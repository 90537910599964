.home-background {
    width: 100%;
    height: 50%;
    position: relative;
}

.title {
    font-weight: bold;
}

.carousel-image {
    width: 100%;
    height: 700px;
    object-fit: cover;
}

.club-info {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #065286;
    background-color: rgba(211, 216, 218, 0.7);
    position: absolute;
    bottom: 10%;
    height: 35%;
    width: 100%;
    z-index: 1;
}

#home-logo-picture {
    height: 100%;
    width: auto;
}

#carousel-text {
    font-size: 2vw;
    font-weight: bold;
}

.club-about {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: auto;
    width: 100%;
    background-color: #065286;
    color: #ffffff;
    padding: 5%;
}

#about-name {
    font-weight: bold;
}

#home-about-text {
    padding: 1% 20%;
    animation: fadeIn 1s ease-in-out;
}

#learn-more-button {

    border-radius: 5px;
    background-color: #0D6EFD;
    font-weight: bold;
}

#learn-more {
    font-weight: bold;
    color: #ffffff;
}

.carousel-control-prev { 
    opacity: 0 !important;
    transition: opacity 0.5s !important;
 }

.carousel-control-prev:hover {
    opacity: 1 !important;
}

.carousel-control-next { 
    opacity: 0 !important;
    transition: opacity 0.5s !important;
 }

.carousel-control-next:hover {
    opacity: 1 !important;
}

#upcoming-events-carousel{
    width: 100% !important;
}
#upcoming-events{
    color: #065286;
    margin: 1% 0;
    border-radius: 10px;
    box-shadow: 0 0 16px 14px hsla(0, 0%, 0%, 0.2);
}


.event-container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    min-height: 900px;
    width: 100%;
    color: #065286;

    background-color: #F0F0F0;
}

.event-name{
    font-weight: bold;
}

.event-text {
    margin: 0 5%;
}

.event-detail{
    margin: 5% 0 ;
}
.event-img {
    margin: 10%;
    width: 30%;
    height: auto; 
    object-fit: cover;
    border-radius: 15px;
    border-bottom: #065286;
}

.club-contact {
    height: 300px;
    width: 100%;
    background-color: #6DC4FF;
    color: #065286;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#contact-name {
    margin: 3% 0;
    font-weight: bold;
}

#home-icon-container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
}

.home-social-icon{
    padding: 10%;
    border: 2px solid #000000; 
    border-radius: 50%;
}
  
/* Responsive Styles */

@media screen and (max-width: 768px) {
    .carousel-image {
        height: 400px;
    }
    #carousel-text {
        font-size: 3vw;
    }
    
    .club-info {
        height: 25%;
    }
    #home-about-text {
        padding: 5%;
    }

    .event-container{
        flex-direction: column;
        min-height: 900px;
    }

    .event-text {
        margin-bottom:15%;
    }
    
    .event-img {
        width: 250px;
    }
    
    .club-contact {
        height: 200px;
    }
}