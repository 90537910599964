
#about-us{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 450px;
    width: 100%; 
    background-color: #065286;
    color: #ffffff;
}

#about-text, #mission-statement-text{
    padding: 0% 20%;
    animation: fadeIn 1s ease-in-out;
}


#mission-statement-block{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 450px;
    width: 100%; 
}

#about-carousel {
    width: 30%;
    height: 450px;
    animation: fadeIn 1s ease-in-out;
}

.about-carousel-image
{
    width: 100%;
    height: 450px;
    object-fit: cover;
}

#mission-statement {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 450px;
    width: 70%; 
    background-color: #6DC4FF;
    color: #065286;
}

#involvement-block{
    color: #065286;
}

#involvement-title{
    margin-top: 5%;
    font-weight: bold;
}

#involvement-text{
    padding: 2% 20%;
    animation: fadeIn 1s ease-in-out;
}

.row {
    display: flex;
    justify-content: space-evenly;
    margin: 2%;
}
  
.column {
    width: 33.33%;
}

.column img {
    width: 100%;
    height: 100%;
    
}

.column-inner{/* Container for img and content and set position to relative and become positional reference for regular-event-content */
    position:relative;
    width: 100%;
    height: 100%;
}

.regular-event-content {
    position: absolute; 
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); 
    color: #f1f1f1; 
    width: 100%;
    height:auto;
    padding: 5%; 
}

.regular-event-name{
    font-weight: bold;
    font-size: 2vw;
}

.regular-event-detail{
    font-size: 1.5vw;
    margin : 1% 0%;
}

.regular-event-detail-reminder{
    color: #065286;
}

#event-link{
    font-weight: bold;
    margin : 3% 0%;
    color: #065286;
}


/* Responsive Styles */

@media screen and (max-width: 768px) {
    #about-us{
        height: 400px;
    }

    #about-text, .title, #mission-statement-text{
        padding: 0% 10%;
    }
    
    #mission-statement-block{
        flex-wrap: wrap;
        height: 900px;
        width: 100%;
    }
    
    #about-carousel {
        width: 100%;
    }
    
    #mission-statement {
        width: 100%; 
    }

    
    #involvement-text{
        padding: 1% 10%;
    }
    
    .row {
        flex-wrap: wrap;
        margin: 0%;
    }
      
    .column {
        width: 100%;
        margin : 2% 0%;
    }
    .regular-event-name{
        font-size: 4vw;
    }
    .regular-event-detail{
        font-size: 3vw;
    }


}