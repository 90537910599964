#contact-header{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 5% 10%;
    color : #ffffff;
    background-color: #065286;
    width: 100%;
    height: 300px;
}

#contact-title{
    font-weight: bold;
    margin-bottom: 1%;
}

#contact-content{
    animation: fadeIn 1s ease-in-out;
}

#contact-method-section{
    animation: fadeIn 1s ease-in-out;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    align-items: center;
    gap: 10%;
    margin: 10%;
    
}

.media-description{
    font-size: 20px;
}

#newsletter-picture{
    width: 80%;
    border-radius: 10%;

}


@media screen and (max-width: 768px) {
    #contact-header{
        height: 200px;
    }
    #contact-method-section{
        gap: 2%;
        margin: 5% 3%;
    }

    .media-description{
        font-size: 12px;
    }

    .icon{
        transform: scale(0.5);
    }

    #instagram-page{
        min-width: 0;
        transform: scale(0.5);
    }
}