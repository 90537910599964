#board-group-picture {
    width: 100%;
    background-image: url('../public/meet_our_board_picture_cropped.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
}

#board-background {
    position: relative;
    width: 100%;
}

#board-background-title {
    position: absolute;
    bottom: 0;
    color : #065286;
    background-color: rgba(211, 216, 218, 0.7);
    width: 100%;
    font-size : 3vw;
    text-align: center;
}

#board-container {
    animation: fadeIn 1s ease-in-out;
    margin-top : 5%;
}

.board-section-container{
    margin : 5%;
}

.board-section-title{
    color : #065286;
    font-weight: bold;
    border-bottom: 1px solid #065286;
}

.board-section{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: space-evenly;
    justify-items: center;
    gap: 5%;
    margin: 2%;
    
}

.board-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 100%;
    padding: 10% 0%;
}


.board-picture {
    width: 100%;
    height: 60%;
    object-fit: cover;
    border-radius: 50%;
}

.board-member-position{
   font-style: italic;
   color: #959b9f;
}

.board-info-container {
    height: 40%;
}

@media (max-width: 1200px) {
    .board-picture {
        width: 15vw; 
        height: 15vw;
    }
}

@media (max-width: 992px) {
    .board-card {
        padding: 25% 0%;
    }
}

 @media (max-width: 768px) {
    #board-background-title {
        font-size : 5vw;
    }
    
    .board-section {
        display: block;
        margin: 2% auto;
        width : 60%;
    }
      
    .board-card {
        width: 100%;
        margin: 15% 0%;
    }
    .board-picture {
        width: 50vw; 
        height: 50vw; 
    }
    
}




