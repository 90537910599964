/* App.css */
body{
  font-family: "Lucida Console", "Oswald" ,"Courier New", monospace;
  font-size: 16px;
  margin: 0;
  padding: 0;
}
@media screen and (max-width: 768px) {
  body{
    font-size: 14px;
    margin: 0;
    padding: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.App {
  text-align: center;
  /* background-color: #abd2e9; */
}

.navbar {
  background-color: #6DC4FF; /* Adjust the color to match your preference */
  margin-right: 0;
}


.navbar-brand {
  color : #065286;
}

.navbar-brand:hover {
  color: #065286; /* Override the hover state defined in Bootstrap NavBar */
}
.navbar-brand:not(:hover) {
  color: #065286;/* Override the hover state defined in Bootstrap NavBar */
}

.page_name {
  border-radius: 5px;
  color: #065286;
}
.page_name:hover {
  background-color:#065286; /*will change background-color of element on hover */
  color: #ffffff;
}